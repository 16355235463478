.faq {
	color: white;
}

.faq-title {
	text-align: center;
	color: #e7bc31;
}
.faq-subtitle {
	text-align: center;
	color: #e7bc31;
	font-size: 20px;
	margin-bottom: 40px;
}

.answer {
	font-size: 24px;
	color: white;
}
.question {
	font-size: 34px;
	color: #e7bc31;
}
