body {
	background-repeat: no-repeat;
	background-position: top;
	font-family: "Sora", sans-serif;
	overflow-x: hidden;
}

.ContentDiv {
	min-height: calc(100vh - 50px);
}
/* Navbar */
.navbar {
	background-image: url("../src/images/smoke.png");
	background-blend-mode: normal;
	background-repeat: no-repeat;
	background-position: left;

	/* border-bottom: 3px solid white; */
	background-color: transparent;
}
.navbar-brand {
	width: 150px;
}
.navbar-toggler {
	color: transparent;
}
.navbar-toggler-icon {
	color: black;
	background-color: transparent;
	border-color: transparent;

	border-radius: 8px;
}
.navbar-toggler:focus {
	outline: 0 !important;
}

.nav-link {
	color: #e7bc31;
	font-size: large;
}
.nav-link:hover {
	color: #e7bc31;

	background: transparent;
}

/* Logo */

.logostyle {
	background-color: transparent;
	border: none;
	outline: none;
}
.logostyle:focus {
	outline: 0 !important;
}
/* Dropdown Menu */
.dropdown .dropdown-menu {
	background-color: black;
}

.dropdown-item {
}

.dropdown-item:hover {
	background: transparent;
}

.spanicon {
	padding-top: 2px;
	color: #daaa01;
}
